import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Row from '@frontend/ui-kit/Components/Row';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Link, {LINK_TYPES} from '@frontend/ui-kit/Components/Link';
import EmptyData from '../../../shared/EmptyData';
import {HOUSTON_URL} from '../../../../constants';
import emptyDataImage from '../../../../static/images/empty_data2.svg';

const ComingSoon = () => {
    const {push} = useHistory();
    const {search} = useLocation();

    return (
        <React.Fragment>
            <ContentSection className='mt-25'>
                <Heading className='mt-5' type={HEADING_TYPES['2']}>Coming soon!</Heading>
                <EmptyData image={emptyDataImage} description={'File upload for Renewal import is not supported in Admin Portal at this time.\nAfter submission of this config in Admin Portal, please visit Houston and upload the accompanying file to complete this import.'}/>
            </ContentSection>

            <Row className='mt-20'>
                <Column xs/>
                <Column constant className='pr-5'>
                    <Button onClick={() => push(`${search}#configuration`)} className='mr-10' type={BUTTON_TYPES.secondary}>Back to Config</Button>
                    <Link isButton type={LINK_TYPES.primary} href={`${HOUSTON_URL}/admin/import_users/`} target='_blank'>Open Houston</Link>
                </Column>
            </Row>
        </React.Fragment>
    );
};

export {ComingSoon as TestableComingSoon};
export default React.memo(ComingSoon);
